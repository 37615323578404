import { useState } from 'react'

import { LoadMore, useFvNavigate } from '@fv/client-components'
import { useQueryParam } from '@/hooks/useQueryParam'

import { OppListingProvider } from '../opportunities/OppListingContextProvider'
import { OppMessaging } from '../opportunities/OppMessaging'
import { useOpportunitySearch } from './queries'
import { SearchForm } from './SearchForm'
import { SearchResults } from './SearchResults'

export const SearchPage = () => {
  const navigate = useFvNavigate()
  const queryParam = useQueryParam('text') ?? ''
  const searchText = decodeURIComponent(queryParam).trim()

  const [value, setValue] = useState(searchText)
  const searchQuery = useOpportunitySearch(searchText.trim())
  const results = (searchQuery.data?.pages || []).reduce(
    (all, { data }) => all.concat(...data),
    [],
  )

  function onValidSubmit() {
    if (searchQuery.isFetching || !value.trim()) return
    const nextSearch = encodeURIComponent(value.trim())
    navigate(`/search?text=${nextSearch}`)
  }

  return (
    <OppListingProvider page="search">
      <main className="col-span-2 row-span-2">
        <SearchForm
          isLoading={searchQuery.isFetching}
          onFieldChange={e => setValue(e.target.value)}
          onValidSubmit={onValidSubmit}
          value={value}
        />

        <SearchResults data={results} />

        <LoadMore
          fetchNextPage={searchQuery.fetchNextPage}
          hasNextPage={searchQuery.hasNextPage}
          isLoading={searchQuery.isFetchingNextPage}
        />
      </main>

      <OppMessaging opportunities={results} />
    </OppListingProvider>
  )
}
