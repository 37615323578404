import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useCacheHelpers } from '@/hooks/opportunities/useCacheHelpers'

import { type Opportunity } from '../../../types/Opportunity'
import { carrierFetch } from '../../../utils/carrierFetch'

interface ArchiveOpts {
  archive: boolean
  loadId: string
}

async function archiveOpportunity({ archive, loadId }: ArchiveOpts) {
  return carrierFetch<Opportunity>(`/loads/${loadId}/archive`, {
    body: { archive },
    method: 'PUT',
  })
}

// Used for both archive/unarchive via the `archive` flag
export function useArchiveOpportunity() {
  const { updateOppCaches, refreshBucketCounts } = useCacheHelpers()

  return useMutation(archiveOpportunity, {
    onSuccess: opportunity => {
      const { isArchived } = opportunity
      const message = `Shipment ${
        isArchived ? 'archived' : 'unarchived'
      } successfully.`

      updateOppCaches(opportunity, ['archived', 'confirmed'])

      toast.success(message)

      setTimeout(() => {
        refreshBucketCounts()
      }, 1000)
    },
  })
}
