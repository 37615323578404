import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Quote } from '@fv/client-types'
import { apiUri } from '@/constants'
import { useCacheHelpers } from '@/hooks/opportunities'
import {
  messagesQueryKeys,
  opportunityQueryKeys,
  quoteQueryKeys,
  quoteRequestKeys,
} from '@/queryKeys'

async function deleteQuote({
  loadId,
  quoteId,
}: {
  loadId: string
  quoteId: string
}) {
  const endpoint = `${apiUri}/loads/${loadId}/quotes/${quoteId}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return { loadId, quoteId }
  throw response.errorMessage
}

export function useRetractQuote(quoteRequestId: string) {
  const { refreshBucketCounts } = useCacheHelpers()
  const queryClient = useQueryClient()

  return useMutation(deleteQuote, {
    onSuccess: ({ loadId, quoteId }) => {
      let hasMoreQuotes = false

      queryClient.setQueryData(
        quoteQueryKeys.load(loadId),
        (prev?: Quote[]) => {
          if (!prev) return []
          hasMoreQuotes = prev.length > 1
          return prev.filter(q => q._id !== quoteId)
        },
      )

      queryClient.invalidateQueries(opportunityQueryKeys.byStatus('offered'))
      if (!hasMoreQuotes) {
        queryClient.invalidateQueries(opportunityQueryKeys.byStatus('open'))
      }

      refreshBucketCounts()

      toast.success('Quote retracted successfully.')

      setTimeout(() => {
        queryClient.invalidateQueries(messagesQueryKeys.load(loadId))
        queryClient.invalidateQueries(opportunityQueryKeys.search())
        queryClient.invalidateQueries(quoteRequestKeys.id(quoteRequestId))
      })
    },
  })
}
