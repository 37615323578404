import { type SortDir, SortDirectionToggle } from '@fv/client-components'
import { type OpportunitySort } from '@fv/models'
import { SelectInput } from '@/components/inputs/SelectInput'

import { useListingQueryParams } from './hooks'
import {
  useOppListingActions,
  useOppListingState,
} from './OppListingContextProvider'
import { QuotingLegend } from './QuotingLegend'

export const OppFilterSortControls = () => {
  const [params, setParams] = useListingQueryParams()
  const { page } = useOppListingState()
  const { setActiveId } = useOppListingActions()

  const handleSortChange = (sort: OpportunitySort) => {
    setParams(p => ({
      ...p,
      sortBy: sort,
      sortDirection: 1,
    }))
    setActiveId('')
  }
  const handleSortDirChange = (sortDirection: '1' | '-1') => {
    setParams(p => ({
      ...p,
      sortDirection: sortDirection === '-1' ? -1 : 1,
    }))
    setActiveId('')
  }
  return (
    <nav className="flex items-center whitespace-nowrap b650:block mb-6">
      <div className="flex items-center gap-x-2">
        <div className="whitespace-nowrap flex items-center gap-x-2 b650:mt-4 b650:mb-2">
          <div className="flex items-center">
            <SelectInput
              className="w-auto mr-2 max-w-[13rem] b650:max-w-none b650:w-full"
              name="sort"
              onChange={e =>
                handleSortChange(e.target.value as OpportunitySort)
              }
              value={params.sortBy || 'pickupDate'}
              options={[
                { text: 'Sort by: Pickup date', value: 'pickupDate' },
                { text: 'Sort by: Bid expiration', value: 'bidExpirationDate' },
                { text: 'Sort by: Last message', value: 'lastMessageDate' },
              ]}
            />
            <SortDirectionToggle
              value={(params.sortDirection?.toString() as SortDir) || '1'}
              onChange={handleSortDirChange}
            />
          </div>
        </div>
      </div>
      {(page === 'quoting' || page === 'won') && (
        <div className="ml-auto">
          <QuotingLegend />
        </div>
      )}
    </nav>
  )
}
