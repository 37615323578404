import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import { type ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'

import { FvButton, TextAreaField, ValidatedForm } from '@fv/client-components'
import { EmptyList } from '@/components/shared/EmptyList'

import { Branding } from './Branding'
import { MessageRow } from './MessageRow'
import { useMessages, useSendMessage } from './queries'

type Props = {
  close?: () => void
  isOpen?: boolean
  opportunity?: {
    isCanceled?: boolean
    loadId: string
    quoteRequestId: string
  }
}
export const MessagingPanel = ({
  close = null,
  isOpen = false,
  opportunity,
}: Props) => {
  const [fields, setFields] = useState({ text: '' })
  const formRef = useRef<HTMLFormElement>(null)

  const { isCanceled, loadId } = opportunity ?? {}
  const messagesQuery = useMessages({ loadId })
  const { sendMessage, isSendingMessage } = useSendMessage(loadId)

  useEffect(() => {
    resetForm()
  }, [loadId])

  const messages = useMemo(() => {
    if (!messagesQuery.data) return []

    return orderBy(
      messagesQuery.data.map(m => ({
        ...m,
        author: m.participants.find(p => p.isAuthor),
      })),
      m => new Date(m.createdDate),
      'desc',
    )
  }, [messagesQuery.data])

  if (!opportunity || messagesQuery.isLoading) {
    return (
      <aside
        className={clsx('col-start-3 row-start-2 row-span-2', {
          flyout: isOpen,
        })}
      >
        <EmptyList
          busy={messagesQuery.isFetching}
          message={
            messagesQuery.isFetching ? 'Loading...' : 'No messages available.'
          }
        />
        <Branding />
      </aside>
    )
  }

  async function onValidSubmit() {
    sendMessage({ messageText: fields.text.trim() })
    resetForm()
  }

  function onFieldChanged(e: ChangeEvent<HTMLTextAreaElement>) {
    const name = e.target.name
    const value = e.target.value
    setFields(f => ({ ...f, [name]: value }))
  }

  function resetForm() {
    setFields({ text: '' })
    formRef.current?.reset()
  }

  return (
    <aside
      className={clsx(
        'col-start-3 row-start-2 row-span-2 b500:w-full b500:border-0',
        {
          flyout: isOpen,
        },
      )}
    >
      {!isCanceled && (
        <ValidatedForm ref={formRef} onValidSubmit={onValidSubmit}>
          <div className="form-group form-group--send-message">
            <div className="input-group input-group--flex flex-auto">
              <TextAreaField
                className="form-control form-control--height-transition"
                disabled={isSendingMessage}
                maxLength={500}
                name="text"
                onChange={onFieldChanged}
                required
                rows={1}
                value={fields.text}
              />
              <FvButton
                theme="default"
                className="-mx-px"
                disabled={isSendingMessage}
                type="submit"
                icon={isSendingMessage ? 'spinner' : 'paper-plane'}
              >
                <span>Send</span>
              </FvButton>
            </div>
            <FvButton type="button" onClick={close} icon="times" />
          </div>
        </ValidatedForm>
      )}

      {messages.length ? (
        messages.map(m => <MessageRow key={m._id} message={m} />)
      ) : (
        <EmptyList message="No messages available." />
      )}

      <Branding />
    </aside>
  )
}
