import { LoadMore } from '@fv/client-components'
import { EmptyList } from '@/components/shared/EmptyList'

import { OppCard } from '../opp-card/OppCard'
import { useAutoSelectFirstOpp, useOppListingOpportunities } from './hooks'
import { OppFilterSortControls } from './OppFilterSortControls'
import { useOppListingState } from './OppListingContextProvider'

type Props = {
  emptyText: string
}
export const OppListing = ({ emptyText }: Props) => {
  const { activeId } = useOppListingState()
  const listQuery = useOppListingOpportunities()
  const { data } = listQuery
  useAutoSelectFirstOpp(data)

  return (
    <main className="b1500:col-span-2 b1000:col-span-3 row-span-2">
      <OppFilterSortControls />

      {data?.length > 0 ? (
        data.map(opportunity => (
          <OppCard
            opportunity={opportunity}
            isActive={opportunity.loadId === activeId}
            key={opportunity.loadId}
          />
        ))
      ) : (
        <EmptyList message={emptyText} busy={listQuery.isLoading} />
      )}

      <LoadMore
        fetchNextPage={listQuery.fetchNextPage}
        hasNextPage={listQuery.hasNextPage}
        isLoading={listQuery.isFetchingNextPage}
      />
    </main>
  )
}
