import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type OpportunityStatus } from '@fv/models'
import { useCacheHelpers } from '@/hooks/opportunities/useCacheHelpers'
import { type Opportunity } from '@/types/Opportunity'

import { carrierFetch } from '../../../utils/carrierFetch'

interface DeclineOpts {
  loadId: string
}
async function declineOpportunity({ loadId }: DeclineOpts) {
  return await carrierFetch<Opportunity>(`/loads/${loadId}`, {
    method: 'DELETE',
  })
}

export function useDeclineOpportunity(status: OpportunityStatus) {
  const { updateOppCaches, refreshBucketCounts } = useCacheHelpers()

  return useMutation(declineOpportunity, {
    onSuccess: opportunity => {
      updateOppCaches(opportunity, [status, 'declined'])
      refreshBucketCounts()

      toast.success('Opportunity declined successfully.')
    },
  })
}
