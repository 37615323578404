import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { type OpportunitySort } from '@fv/models'
import { removeUndef } from '@fv/models/core'
import { type Opportunity } from '@/types/Opportunity'

import {
  useOppListingActions,
  useOppListingState,
} from './OppListingContextProvider'
import { type OppListingParams, useOpportunities } from './queries'

type Func = (prev: OppListingParams) => OppListingParams

export const useListingQueryParams = (): [
  OppListingParams,
  (setter: Func) => void,
] => {
  const [params, setParams] = useSearchParams()
  const accountId = params.get('accountId')
  const sortBy = params.get('sortBy') as OpportunitySort
  const sortDir = params.get('sortDirection') === '-1' ? -1 : 1
  const listParams: OppListingParams = {
    ...(accountId && { accountId }),
    sortBy: sortBy || 'pickupDate',
    sortDirection: sortDir,
  }
  return [
    listParams,
    cb => {
      const newParams = cb(listParams)
      setParams(p => ({
        ...p,
        ...removeUndef(newParams),
      }))
    },
  ]
}

export const useOppListingOpportunities = () => {
  const { status } = useOppListingState()
  const [params] = useListingQueryParams()
  return useOpportunities(status, params)
}

export const useAutoSelectFirstOpp = (opps?: Opportunity[]) => {
  const { activeId } = useOppListingState()
  const { setActiveId } = useOppListingActions()

  useEffect(() => {
    const prevItem = opps?.find(d => d.loadId === activeId)
    if (!prevItem && opps[0]?.loadId) {
      setActiveId(opps[0]?.loadId)
    }
  }, [opps, activeId, setActiveId])
}
