import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useCacheHelpers } from '@/hooks/opportunities/useCacheHelpers'

import { type Opportunity } from '../../../types/Opportunity'
import { carrierFetch } from '../../../utils/carrierFetch'

interface ConfirmOpts {
  loadId: string
  message: string
  pickupNumber: string
}
async function confirmLoad({ loadId, message, pickupNumber }: ConfirmOpts) {
  return await carrierFetch<Opportunity>(`/loads/${loadId}/confirm`, {
    body: {
      message,
      pickupNumber,
    },
    method: 'POST',
  })
}

export function useConfirmLoad() {
  const { updateOppCaches, refreshBucketCounts } = useCacheHelpers()

  return useMutation(confirmLoad, {
    onSuccess: opportunity => {
      updateOppCaches(opportunity, ['awarded', 'confirmed'])

      refreshBucketCounts()

      toast.success('Load confirmed successfully.')
    },
  })
}
