import { type Opportunity } from '@/types/Opportunity'

import { MessagingPanel } from '../messaging/MessagingPage'
import { useOppListingOpportunities } from './hooks'
import {
  useOppListingActions,
  useOppListingState,
} from './OppListingContextProvider'

type Props = {
  opportunities?: Opportunity[]
}
export const OppMessaging = ({ opportunities }: Props) => {
  const { messagesOpen, activeId } = useOppListingState()
  const { setMessagesOpen } = useOppListingActions()
  const storeOppQuery = useOppListingOpportunities()
  const opps = opportunities ?? storeOppQuery.data ?? []
  const opp = opps?.find(o => o.loadId === activeId)
  return (
    <MessagingPanel
      isOpen={messagesOpen}
      close={() => setMessagesOpen(false)}
      opportunity={activeId ? opp : undefined}
    />
  )
}
