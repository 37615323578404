import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useCacheHelpers } from '@/hooks/opportunities'

import { type Opportunity } from '../../../types/Opportunity'
import { carrierFetch } from '../../../utils/carrierFetch'

interface UndeclineOpts {
  loadId: string
}
async function undeclineOpportunity({ loadId }: UndeclineOpts) {
  return await carrierFetch<Opportunity>(`/loads/${loadId}/decline`, {
    method: 'DELETE',
  })
}

export function useUndeclineOpportunity() {
  const { updateOppCaches, refreshBucketCounts } = useCacheHelpers()

  return useMutation(undeclineOpportunity, {
    onSuccess: opportunity => {
      updateOppCaches(opportunity, ['declined', opportunity.status])
      refreshBucketCounts()

      toast.success('Opportunity reopened successfully.')
    },
  })
}
