import clsx from 'clsx'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import { FvButton, Tooltip } from '@fv/client-components'
import { supportMessage } from '@/constants'

import { type Opportunity } from '../../types/Opportunity'
import { getBidExpiration } from '../../utils/getBidExpiration'
import {
  useOppListingActions,
  useOppListingState,
} from '../opportunities/OppListingContextProvider'
import {
  type OppListingStatus,
  type OppPage,
  oppPageStatusMap,
} from '../opportunities/types'
import { useArchiveOpportunity } from './hooks/useArchiveOpportunity'
import { useRejectLoad } from './hooks/useRejectLoad'
import { CardActions } from './CardActions'
import { ConfirmationForm } from './ConfirmationForm'
import { ExpirationNotice } from './ExpirationNotice'
import { QuoteForm } from './QuoteForm'
import { ShipmentDetails } from './ShipmentDetails'
import { ShipperCanceledOverlay } from './ShipperCanceledOverlay'

type Props = {
  isActive: boolean
  opportunity: Opportunity
}

const getPageFromStatus = (status: OppListingStatus): OppPage => {
  return Object.entries(oppPageStatusMap).find(([, statuses]) =>
    [...statuses].includes(status),
  )?.[0] as OppPage
}

const bidExpirationStatuses: OppListingStatus[] = ['offered', 'open']
export const OppCard = ({ isActive, opportunity }: Props) => {
  const { setActiveId } = useOppListingActions()
  const { status, page: storePage } = useOppListingState()
  const { bidExpirationDate, isExpired } = getBidExpiration(opportunity)
  const oppStatus = opportunity.status
  const [showQuoteForm, toggleQuoteForm, setShowQuoteForm] = useToggle()
  // 90% of the time page will not change depending on the status, so keep this in the store for cacheability
  const page =
    storePage === 'search' || storePage === 'direct'
      ? getPageFromStatus(oppStatus)
      : storePage

  const { isArchived, isLegacy, loadId } = opportunity
  const rejectLoad = useRejectLoad()
  const archive = useArchiveOpportunity()
  const archiveIcon = isArchived ? 'trash-restore-alt' : 'archive'
  function archiveQuoteRequest(shouldArchive: boolean) {
    if (archive.isLoading || rejectLoad.isLoading) return

    archive.mutateAsync({ archive: shouldArchive, loadId }).catch(() => {
      toast.error(`Unable to archive shipment, ${supportMessage}`)
    })
  }
  function rejectActiveLoad() {
    if (archive.isLoading || rejectLoad.isLoading) return

    if (
      window.confirm(
        "By clicking 'ok' you are acknowledging that you can no longer take this load even though you have already confirmed with the shipper. We will notify the shipper in Freightview and via email.",
      )
    ) {
      rejectLoad.mutateAsync({ loadId }).catch(() => {
        toast.error(`Unable to decline load, ${supportMessage}`)
      })
    }
  }
  useEffect(() => {
    if (showQuoteForm && !isActive) setShowQuoteForm(false)
  }, [isActive, showQuoteForm, setShowQuoteForm])
  return (
    <article
      className={clsx('shipment-item opacity-80', {
        'shipment-item--selected border-fv-orange b1000:border-r border-r-0 !opacity-100':
          isActive,
        'pb-10': page === 'active',
        'pb-6': page !== 'active',
      })}
      onClick={() => setActiveId(opportunity.loadId)}
    >
      {bidExpirationStatuses.includes(status) && bidExpirationDate && (
        <ExpirationNotice isActive={isActive} page={'opportunity'}>
          {isExpired ? 'Bidding expired on' : 'Bidding expires on '}{' '}
          {bidExpirationDate}
        </ExpirationNotice>
      )}
      <div className="shipment-item__initial">
        {page !== 'open' && <CardActions opportunity={opportunity} />}
        <ShipmentDetails opportunity={opportunity} />
        {page === 'open' && (
          <CardActions
            opportunity={opportunity}
            onToggleQuoteForm={toggleQuoteForm}
          />
        )}
      </div>

      {isActive && oppStatus === 'awarded' && (
        <ConfirmationForm opportunity={opportunity} />
      )}

      {(showQuoteForm ||
        (isActive && page === 'quoting' && status !== 'lost')) && (
        <QuoteForm opportunity={opportunity} hasQuoted={page === 'quoting'} />
      )}

      {page === 'active' && (
        <div
          className={`absolute bottom-0 flex ${
            isActive ? 'right-12' : 'right-4'
          }`}
        >
          <Tooltip
            label={
              opportunity.isArchived ? 'Move back to active queue' : 'Archive'
            }
          >
            <FvButton
              onClick={() => archiveQuoteRequest(!isArchived)}
              theme="plain"
              icon={archiveIcon}
              loading={archive.isLoading}
            />
          </Tooltip>

          {isLegacy === false && (
            <Tooltip label="Decline this load">
              <FvButton
                className="m-0"
                loading={rejectLoad.isLoading}
                icon="ban"
                onClick={rejectActiveLoad}
                theme="plain"
              />
            </Tooltip>
          )}
        </div>
      )}

      {opportunity.isCanceled && (
        <ShipperCanceledOverlay
          canArchive={!opportunity.isArchived}
          opportunity={opportunity}
        />
      )}
    </article>
  )
}
