import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { apiUri } from '@/constants'
import { useCacheHelpers } from '@/hooks/opportunities/useCacheHelpers'

import { type Opportunity } from '../../../types/Opportunity'

async function editConfirmationNumber(d: {
  confirmationNumber: string
  loadId: string
}): Promise<Opportunity> {
  const { confirmationNumber, loadId } = d
  const endpoint = `${apiUri}/loads/${loadId}/confirm`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ confirmationNumber }),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useEditConfirmationNumber() {
  const { updateOppCaches } = useCacheHelpers()

  return useMutation(editConfirmationNumber, {
    onSuccess: opportunity => {
      updateOppCaches(opportunity, 'confirmed')

      toast.success('Confirmation number updated.')
    },
  })
}
