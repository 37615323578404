import { opportunityStatuses } from '@fv/models'

export interface OppListingState {
  activeId?: string
  messagesOpen?: boolean
  status: OppListingStatus
  page: OppPage
}
export interface OppListingActions {
  setMessagesOpen: (messagesOpen: boolean) => void
  setActiveId: (activeId: string) => void
}

export interface OppListingStore extends OppListingState {
  actions: OppListingActions
}

export const oppListingStatuses = [...opportunityStatuses, 'archived'] as const
export type OppListingStatus = (typeof oppListingStatuses)[number]

export type OppPage =
  | 'open'
  | 'quoting'
  | 'won'
  | 'active'
  | 'search'
  | 'direct'
export const oppPageStatusMap = {
  open: ['open', 'declined'],
  quoting: ['offered', 'lost'],
  won: ['awarded'],
  active: ['confirmed', 'archived'],
} as const
